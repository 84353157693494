import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateService } from '@ngx-translate/core';
import { Actions, Select, Store, ofActionCompleted } from '@ngxs/store';
import { WorkflowScreen } from 'app/json-schema-forms/store/models/workflow-screen.model';
import { Workflow } from 'app/json-schema-forms/store/models/workflow.model';
import { FetchWorkflow } from 'app/json-schema-forms/store/workflow.actions';
import { WorkflowState } from 'app/json-schema-forms/store/workflow.state';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { JsonSchemaService } from '../../services/json-schema.service';

export class Language {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-forms-generator',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormlyMaterialModule,
    FormlyModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
  ],
  providers: [JsonSchemaService, FormlyJsonschema],
  templateUrl: './forms-generator.component.html',
  styleUrl: './forms-generator.component.scss',
})
export class FormsGeneratorComponent implements OnInit, AfterViewInit, OnDestroy {
  private $destroy: Subject<any> = new Subject<any>();
  form: FormGroup = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];

  languages: Language[] = [
    { value: 'en', viewValue: 'English' },
    { value: 'fr', viewValue: 'French' },
  ];

  selectedLanguage = this.languages[0];

  @Select(WorkflowState.getWorkflow) getWorkflow: Observable<Workflow>;
  @Select(WorkflowState.getCurrentScreen) getCurrentScreen: Observable<WorkflowScreen>;

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly formlyJsonschema: FormlyJsonschema,
    private readonly jsonSchemaService: JsonSchemaService,
    public translate: TranslateService,
    private readonly router: Router
  ) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();

    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    this.model.lang = translate.currentLang;
  }
  ngAfterViewInit(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.loadDataFromGQL();
    this.actions$
      .pipe(
        takeUntil(this.$destroy),
        ofActionCompleted(FetchWorkflow),
        tap(_ => {})
      )
      .subscribe();

    // this.test();
  }

  test(): void {
    //todo: remove hardcoded value
    this.store$.dispatch(new FetchWorkflow('0f07e516-7ac7-49ae-813b-e272e2e18907'));

    this.jsonSchemaService
      .loadJsonFile()
      .pipe(
        tap(({ schema, model }) => {
          console.log(model);
          this.form = new FormGroup({});
          this.options = {};
          this.fields = [
            this.formlyJsonschema.toFieldConfig(schema, {
              map: field => {
                if (field.props['tabs']) {
                  field.type = 'tabs';
                }

                if (field.props['navigate']) {
                  field.props['onClick'] = () => {
                    this.router.navigate([field.props['navigate']]);
                  };
                }
                if (field.key === 'nextButton') {
                  if (field.props['gql']) {
                    field.props['onClick'] = () => {
                      const { action, variables } = field.props;
                      this.handleGraphqlMutation(action, variables);
                      // this.nextPage();
                    };
                  } else {
                    // field.props['onClick'] = this.nextPage.bind(this);
                  }
                } else {
                  if (field.props['gql']) {
                    field.props['onClick'] = () => {
                      const { action, variables } = field.props;
                      this.handleGraphqlMutation(action, variables);
                    };
                  }
                }
                return field;
              },
            }),
          ];
          this.model = model;
        }),
        takeUntil(this.$destroy)
      )
      .subscribe();
  }

  loadDataFromGQL(): void {
    this.store$.dispatch(new FetchWorkflow('0f07e516-7ac7-49ae-813b-e272e2e18907'));
    this.getCurrentScreen
      .pipe(
        takeUntil(this.$destroy),
        //Added any type because  toFieldConfig() only gets JSONSchema7 type args and schema is of type WorkflowConfig
        tap((screen: any) => {
          if (screen) {
            this.form = new FormGroup({});
            this.options = {};
            this.fields = [
              this.formlyJsonschema.toFieldConfig(screen?.schema, {
                map: field => {
                  if (field.props['tabs']) {
                    field.type = 'tabs';
                  }

                  if (field.props['navigate']) {
                    if (field.props['gql']) {
                      field.props['onClick'] = () => {
                        const { action, variables } = field.props;
                        this.handleGraphqlMutation(action, variables);
                        this.router.navigate([field.props['navigate']]);
                      };
                    } else {
                      field.props['onClick'] = () => {
                        this.router.navigate([field.props['navigate']]);
                      };
                    }
                  }
                  if (field.key === 'nextButton') {
                    if (field.props['gql']) {
                      field.props['onClick'] = () => {
                        const { action, variables } = field.props;
                        this.handleGraphqlMutation(action, variables);
                        // this.nextPage();
                      };
                    } else {
                      // field.props['onClick'] = this.nextPage.bind(this);
                    }
                  } else {
                    if (field.props['navigate']) {
                      if (field.props['gql']) {
                        field.props['onClick'] = () => {
                          const { action, variables } = field.props;
                          this.handleGraphqlMutation(action, variables);
                          this.router.navigate([field.props['navigate']]);
                        };
                      } else {
                        field.props['onClick'] = () => {
                          this.router.navigate([field.props['navigate']]);
                        };
                      }
                    } else {
                      if (field.props['gql']) {
                        const { action, variables } = field.props;
                        this.handleGraphqlMutation(action, variables);
                      }
                    }
                  }
                  return field;
                },
              }),
            ];
            this.model = screen?.model;
          }
        })
      )
      .subscribe();
  }

  submit() {
    console.log('form submitted: ', this.model);
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  /**
   * Temporarily injects mock data into the provided mutation variables.
   * This function modifies the original mutationVariables object by adding
   * mock values for 'orgId', 'tickerCode', and 'operationId'.
   *
   * @param mutationVariables - The original mutation variables object.
   * @returns The modified mutation variables object with injected mock data.
   */
  injectMockData(mutationVariables: object): object {
    mutationVariables['orgId'] = 'org-22';
    mutationVariables['tickerCode'] = 'ORG-22';
    mutationVariables['operationId'] = 'operation-id';

    return mutationVariables;
  }

  /**
   * Handles the preparation and execution of a GraphQL mutation.
   * This function extracts the necessary variables from the given input,
   * temporarily injects mock data into the variables, and then executes
   * the GraphQL mutation with the modified variables.
   *
   * @param action - The GraphQL mutation action string.
   * @param variables - The initial variables object to be extracted and modified.
   */
  handleGraphqlMutation(action: string, variables: object) {
    if (action) {
      const mutationVariables = this.jsonSchemaService.variableExtractor(variables, this.model);
      const mutationVariablesModified = this.injectMockData(mutationVariables);
      this.jsonSchemaService.graphqlMutation(action, mutationVariablesModified);
    }
  }
}
