import { UploadedFileType } from '../../../modules/wizard/models/uploaded-file.type';

export class AddUploadedFile {
  static readonly type = '[Document Upload] Add Uploaded File';
  constructor(
    public categoryName: string,
    public file: UploadedFileType
  ) {}
}

export class UpdateUploadedFile {
  static readonly type = '[Document Upload] Update Uploaded File';
  constructor(
    public categoryName: string,
    public file: UploadedFileType
  ) {}
}

export class DeleteUploadedFile {
  static readonly type = '[Document Upload] Delete Uploaded File';
  constructor(
    public categoryName: string,
    public fileId: string
  ) {}
}
