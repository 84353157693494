import { HeaderComponent } from '@shared/components/header/header.component';
import { OrderDocumentUploadComponent } from '@shared/components/order-document-upload/order-document-upload.component';
import { TenderSummaryComponent } from 'app/modules/tendering/components/tender-summary/tender-summary.component';
import { ActivityContainerComponent } from 'app/modules/wizard/components/activity-container/activity-container.component';
import { CreateJobFileExternalPartyComponent } from 'app/modules/wizard/components/create-job-file-external-party/create-job-file-external-party.component';
import { FrequentSearchesComponent } from 'app/modules/wizard/components/frequent-searches/frequent-searches.component';
import { GeneralDetailsComponent } from 'app/modules/wizard/components/general-details/general-details.component';
import { OrderCardComponent } from 'app/modules/wizard/components/order-card/order-card.component';
import { OrderPackingListTableComponent } from 'app/modules/wizard/components/order-packing-list-table/order-packing-list-table.component';
import { OrderShipmentMethodSelectorComponent } from 'app/modules/wizard/components/order-shipment-method-selector/order-shipment-method-selector.component';
import { OrdersContainerComponent } from 'app/modules/wizard/components/orders-container/orders-container.component';
import { OrdersShipmentsTabGroupComponent } from 'app/modules/wizard/components/orders-shipments-tab-group/orders-shipments-tab-group.component';
import { OrdersComponent } from 'app/modules/wizard/components/orders/orders.component';
import { RouteDetailsSummaryComponent } from 'app/modules/wizard/components/route-details-summary/route-details-summary.component';
import { ServiceCardJobSummaryComponent } from 'app/modules/wizard/components/service-card-job-summary/service-card-job-summary.component';
import { ServiceShipmentMethodSelectorComponent } from 'app/modules/wizard/components/service-shipment-method-selector/service-shipment-method-selector.component';
import { ServiceTranshipmentWizardComponent } from 'app/modules/wizard/components/service-transhipment-wizard/service-transhipment-wizard.component';
import { ShipmentDocumentWizardComponent } from 'app/modules/wizard/components/shipment-document-wizard/shipment-document-wizard.component';
import { ShipmentPackingListTableComponent } from 'app/modules/wizard/components/shipment-packing-list-table/shipment-packing-list-table.component';
import { ShipmentReferenceWithServicesComponent } from 'app/modules/wizard/components/shipment-reference-with-services/shipment-reference-with-services.component';
import { ShipmentSummaryComponent } from 'app/modules/wizard/components/shipment-summary/shipment-summary.component';
import { ShipmentsComponent } from 'app/modules/wizard/components/shipments/shipments.component';
import { UnsmartenedAssignServicesContainerComponent } from 'app/modules/wizard/components/unsmartened-assign-services-container/unsmartened-assign-services-container.component';
import { UnsmartenedOrdersContainerComponent } from 'app/modules/wizard/components/unsmartened-orders-container/unsmartened-orders-container.component';
import { AutocompleteChipComponent } from './components/custom/autocomplete-chip/autocomplete-chip.component';
import { AutocompleteTypeComponent } from './components/custom/autocomplete-type/autocomplete-type.component';
import { ButtonTypeComponent } from './components/custom/button-type/button-type.component';
import { ChipTypeComponent } from './components/custom/chip-type/chip-type.component';
import { DatetimePickerTypeComponent } from './components/custom/datetime-picker-type/datetime-picker-type.component';
import { DecimalThousandNumberTypeComponent } from './components/custom/decimal-thousand-number-type/decimal-thousand-number-type.component';
import { LabelTypeComponent } from './components/custom/label-type/label-type.component';
import { SelectTypeComponent } from './components/custom/select-type/select-type.component';
import { TabsTypeComponent } from './components/custom/tabs-type/tabs-type.component';
import { ArrayTypeComponent } from './components/wrappers/array-type/array-type.component';
import { FullScreenWrapperComponent } from './components/wrappers/full-screen-wrapper/full-screen-wrapper.component';
import { MultiSchemaTypeComponent } from './components/wrappers/multi-schema-type/multi-schema-type.component';
import { NullTypeComponent } from './components/wrappers/null-type/null-type.component';
import { ObjectTypeComponent } from './components/wrappers/object-type/object-type.component';
import { PanelWrapperWithHeaderComponent } from './components/wrappers/panel-wrapper-with-header/panel-wrapper-with-header.component';
import { PanelWrapperComponent } from './components/wrappers/panel-wrapper/panel-wrapper.component';
import { RowWrapperComponent } from './components/wrappers/row-wrapper/row-wrapper.component';

export const COMPONENT_CONFIG = {
  validationMessages: [
    { name: 'required', message: 'This field is required' },
    { name: 'maxLength', message: 'This field cannot exceed 100 characters' },
  ],
  types: [
    {
      name: 'autocomplete',
      component: AutocompleteTypeComponent,
    },
    {
      name: 'chip',
      component: ChipTypeComponent,
    },
    {
      name: 'datetimePicker',
      component: DatetimePickerTypeComponent,
    },
    {
      name: 'button',
      component: ButtonTypeComponent,
    },
    { name: 'object', component: ObjectTypeComponent },
    { name: 'array', component: ArrayTypeComponent },
    { name: 'multischema', component: MultiSchemaTypeComponent },
    {
      name: 'null',
      component: NullTypeComponent,
      wrappers: ['form-field'],
    },
    {
      name: 'label',
      component: LabelTypeComponent,
    },
    {
      name: 'dynamicSelect',
      component: SelectTypeComponent,
    },
    {
      name: 'autocompleteChip',
      component: AutocompleteChipComponent,
    },
    { name: 'ordersContainer', component: OrdersContainerComponent },
    { name: 'activityContainer', component: ActivityContainerComponent },
    { name: 'serviceCardJobSummaryContainer', component: ServiceCardJobSummaryComponent },
    { name: 'orderShipmentMethodSelectorContainer', component: OrderShipmentMethodSelectorComponent },
    { name: 'serviceShipmentMethodSelectorContainer', component: ServiceShipmentMethodSelectorComponent },
    { name: 'ordersSmartComponent', component: OrdersComponent },
    { name: 'shipmentsSmartComponent', component: ShipmentsComponent },
    { name: 'oceanFreightRouteDetailsSummary', component: RouteDetailsSummaryComponent },
    { name: 'shipmentPackingListTable', component: ShipmentPackingListTableComponent },
    { name: 'orderPackingList', component: OrderPackingListTableComponent },
    { name: 'orderDocumentWizard', component: OrderDocumentUploadComponent },
    { name: 'shipmentDocumentWizard', component: ShipmentDocumentWizardComponent },
    { name: 'serviceTranshipmentWizard', component: ServiceTranshipmentWizardComponent },
    { name: 'decimalThousandNumber', component: DecimalThousandNumberTypeComponent },
  ],
  wrappers: [
    { name: 'tabs', component: TabsTypeComponent },
    { name: 'panel', component: PanelWrapperComponent },
    { name: 'header', component: HeaderComponent },
    { name: 'itemWrapper', component: RowWrapperComponent },
    { name: 'fullScreenWrapper', component: FullScreenWrapperComponent },
    { name: 'frequentSearch', component: FrequentSearchesComponent },
    { name: 'createJobFileExternalParty', component: CreateJobFileExternalPartyComponent },
    { name: 'generalDetails', component: GeneralDetailsComponent },
    { name: 'tenderSummary', component: TenderSummaryComponent },
    { name: 'panelWrapperWithHeader', component: PanelWrapperWithHeaderComponent },
    { name: 'shipmentSummary', component: ShipmentSummaryComponent },
    { name: 'shipmentReferenceWithServices', component: ShipmentReferenceWithServicesComponent },
    { name: 'orderCard', component: OrderCardComponent },
    { name: 'ordersShipmentTabGroup', component: OrdersShipmentsTabGroupComponent },
    { name: 'unsmartenedOrdersContainer', component: UnsmartenedOrdersContainerComponent },
    { name: 'unsmartenedAssignServicesContainer', component: UnsmartenedAssignServicesContainerComponent },
  ],
};
